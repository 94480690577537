import logo from './logo.svg';
import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Register} from "./pages/Register";
import {Error} from "./pages/Error";

function App() {
  return (
    <div>
      <CssBaseline/>
      <Routes>
        <Route path={'/'} element={<Home/>}/>
        <Route path={'/error'} element={<Error/>}/>
        <Route path={'/register/:id'} element={<Register/>}/>
      </Routes>
    </div>
  );
}

export default App;
