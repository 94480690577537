import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {createValidationSchema} from "../functions/createValidationScheme";
import {registrationMap} from "../assets/data/registrationMap";
import {FormContent} from "../components/Register/FormContent";
import {useNavigate, useParams} from "react-router-dom";
import {Formik} from "formik";
import axios from "axios";
import {API_URL} from "../config";
import {AlertBar} from "../components/shared/AlertBar";
import {Main} from "../layout/Main";
import useAxios from "axios-hooks";
import {filter} from "lodash";
import {saraTheme} from "../theme/saraTheme";
import {useTranslation} from "react-i18next";

const _data = {
    nome: "Matilde",
    cognome: "Ugolini",
    email: "matilde@plesh.co",
    categoria: "AGENTE",
    codiceAgenzia: "asd",

    bari: "si",
    roma: "si",
    milano: "si",
}

export const Register = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const [loading, setLoading] = React.useState(false)
    const [sending, setSending] = React.useState(false)

    /** MOCK DATA DA TOGLIERE */
    const [initialValues, setInitialValues] = React.useState({})

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [{data, loading: dataLoading, error: dataError}] = useAxios(`${API_URL}profile/${id}`, {manual: false})
    const [categoria, setCategoria] = useState("")
    const [partecipazione, setPartecipazione] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (dataError) {
            navigate('/error')
        }
        if (data && !dataLoading && !dataError) {
            setCategoria(data.categoria)
            setPartecipazione(data.partecipazione)
            getData()
        }
    }, [data, dataLoading, dataError])

    const getData = async () => {
        setLoading(true)
        let d = {
            categoria: data.categoria,

            bari: data.bari,
            roma: data.roma,
            milano: data.milano,
        }
        registrationMap.map(s => {
            return filter(s.fields, (f => (f.type !== 'note' && f.type !== 'agenda'))).forEach(f => {
                    let key = f.id
                    if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                        d[key] = data[key] ?? null
                    } else if (f.type === 'checkbox') {
                        d[key] = data[key] ?? false
                    } else {
                        d[key] = data[key] ?? ''
                    }
                }
            )
        })
        setInitialValues(d)
        setLoading(false)
    }

    const yupSchema = () => {
        let fields = []
        registrationMap.map(s => {
            return filter(s.fields, (f => (f.type !== 'note' && f.type !== 'agenda'))).forEach(f => {
                fields.push(f)
            })
        })

        let schema = createValidationSchema(fields)
        return schema
    }

    const validateSchema = yupSchema();

    const sendData = async (values) => {
        setSending(true)
        await axios.post(`${API_URL}register/${id}`, values)
            .then(res => setSuccess(true))
            .catch(res => {
                setErrorMessage(res.response.data.error)
                setError(true)
            })
        setSending(false)
    }

    return (
        <Main>
            <AlertBar open={error} setOpened={setError} severity={'error'} message={errorMessage}/>
            <Container maxWidth={'md'} sx={{py: 2}}>
                {
                    (success || !!partecipazione) ?
                        <Box>
                            <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
                                Registrazione avvenuta con successo!
                            </Typography>
                            <br/>
                            {
                                ["DIRETTORE", "PRESIDENTE"].includes(categoria) ?
                                    <Typography variant={'body1'} textAlign={'center'}>
                                        Nel caso in cui avesse confermato la sua partecipazione, riceverà una risposta
                                        automatica
                                        con il <b>QR CODE</b> che le permetterà di accedere all’evento.
                                        <br/>
                                        Lo stampi oppure lo conservi sul suo smartphone.
                                        <br/>
                                        <br/>
                                        Nel caso in cui non dovesse ricevere l’email la preghiamo di controllare la
                                        cartella SPAM
                                        oppure scrivere alla Segreteria Organizzativa all’indirizzo <a
                                        style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                                        href={'mailto:segreteria@roadshowsara2024.it'}
                                        target={'_blank'}>segreteria@roadshowsara2024.it</a>
                                    </Typography>
                                    :
                                    <Typography variant={'body1'} textAlign={'center'}>
                                        Nel caso in cui avessi confermato la tua partecipazione, riceverai una risposta
                                        automatica
                                        con il <b>QR CODE</b> che ti permetterà di accedere all’evento.
                                        <br/>
                                        Stampalo oppure conservalo sul tuo smartphone.
                                        <br/>
                                        <br/>
                                        Nel caso in cui non dovessi ricevere l’email ti preghiamo di controllare la
                                        cartella SPAM
                                        oppure scrivere alla Segreteria Organizzativa all’indirizzo <a
                                        style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                                        href={'mailto:segreteria@roadshowsara2024.it'}
                                        target={'_blank'}>segreteria@roadshowsara2024.it</a>
                                    </Typography>
                            }
                        </Box>
                        :
                        (dataLoading || Object.keys(initialValues).length <= 0) ?
                            <Box sx={{textAlign: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Stack>
                                <Formik
                                    validationSchema={validateSchema}
                                    initialValues={{...initialValues}}
                                    validateOnChange={true}
                                    validateOnBlur={true}
                                    validateOnMount={false}
                                    onSubmit={values => {
                                        sendData(values)
                                    }}
                                >
                                    {(formik) =>
                                        <form onSubmit={formik.handleSubmit}>
                                            <FormContent formik={formik} formMap={registrationMap}
                                                         categoria={categoria}/>
                                            <Box sx={{textAlign: 'center', pt: 2}}>
                                                {
                                                    sending ? <CircularProgress/> :
                                                        <Button sx={{width: '20%'}} variant={'contained'}
                                                                type={'submit'}>
                                                            Invia!
                                                        </Button>

                                                }
                                            </Box>
                                        </form>
                                    }
                                </Formik>
                                <Stack my={5}>
                                    <Box mb={5} sx={{
                                        width: '100%',
                                        height: '0.05rem',
                                        background: saraTheme.palette.secondary.main
                                    }}/>
                                    <Typography>
                                        {t('contattare', {contattare: ["DIRETTORE", "PRESIDENTE"].includes(categoria) ? 'contatti' : 'contatta'})}
                                        <a style={{color: saraTheme.palette.secondary.main, fontWeight: "bold"}}
                                           href={"mailto:segreteria@roadshowsara2024.it"}
                                           target={"_blank"}>segreteria@roadshowsara2024.it</a> / <br/>tel. 06 6220 6866.
                                    </Typography>
                                </Stack>
                            </Stack>
                }
            </Container>
        </Main>
    )
}
