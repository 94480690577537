import React, {useEffect, useState} from "react";
import {Box, Typography, Grid, useTheme, Stack, Divider} from "@mui/material";
import {FormField} from "./FormField";
import {mappaTermini} from "../../assets/data/mappaTermini";
import {Trans, useTranslation} from "react-i18next";
import {saraTheme} from "../../theme/saraTheme";

export const FormContent = ({formik, formMap, profile, categoria}) => {
    console.log("formik:",formik.values)
    const {t} = useTranslation()
    const Title = ({title}) => {
        return (<Box sx={{mt:4, pb:1}}>
            <Typography fontWeight={'bold'} variant={'h6'} sx={{
                textDecoration: 'underline',
                textDecorationColor: saraTheme.palette.secondary.main,
                textUnderlineOffset: '8px',
                textDecorationThickness: '4px'
            }}>
                {t(title, {compila: ["DIRETTORE", "PRESIDENTE"].includes(categoria) ? 'Compili' : 'Compila'})}
            </Typography>
        </Box>)
    }

    return (
        <Box>
            {formMap.map((section, index) => {
                if (section.conditions) {
                    let render = []
                    section.conditions.predicates.forEach(predicate => {
                        let checked = formik.values[predicate.id] === predicate.value
                        render.push(checked)
                    })
                    if (render.includes(true)) {

                        return <Box mt={1} key={index}>
                            <Title title={section.title}/>
                            <Grid container spacing={2}>
                                {section.fields?.map((field, index) => {
                                        if (field.conditions) {
                                            let render = []
                                            field.conditions.predicates.forEach(predicate => {
                                                let checked = formik.values[predicate.id] === predicate.value
                                                render.push(checked)
                                            })
                                            if (render.includes(true)) {
                                                return <FormField formik={formik} key={index} name={field.name}
                                                                  id={field.id}
                                                                  label={field.label}
                                                                  type={field.type} values={field.values ?? []}
                                                                  cols={field.cols}
                                                                  precompiled={field.precompiled}
                                                                  disablePast={field.disablePast}
                                                                  disableFuture={field.disableFuture}
                                                                  row={field.row}
                                                                  profile={profile}
                                                                  categoria={categoria}
                                                />
                                            }
                                        } else {
                                            return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                              label={field.label}
                                                              type={field.type} values={field.values ?? []}
                                                              cols={field.cols}
                                                              precompiled={field.precompiled}
                                                              disablePast={field.disablePast}
                                                              disableFuture={field.disableFuture}
                                                              row={field.row}
                                                              profile={profile}
                                                              categoria={categoria}
                                            />
                                        }
                                    }
                                )}
                            </Grid>
                        </Box>
                    }
                } else {
                    return <Box key={index} sx={{py: 1}}>
                        <Title title={section.title}/>
                        <Grid container spacing={2}>
                            {section.fields.map((field, index) => {
                                    if (field.conditions) {
                                        let render = []
                                        field.conditions.predicates.forEach(predicate => {
                                            let checked = formik.values[predicate.id] === predicate.value
                                            render.push(checked)
                                        })
                                        if (render.includes(true)) {
                                            return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                              label={field.label}
                                                              type={field.type} values={field.values ?? []}
                                                              cols={field.cols}
                                                              precompiled={field.precompiled}
                                                              disablePast={field.disablePast}
                                                              disableFuture={field.disableFuture}
                                                              row={field.row}
                                                              profile={profile}
                                                              categoria={categoria}
                                            />
                                        }
                                    } else {
                                        return <FormField formik={formik} key={index} name={field.name} id={field.id}
                                                          label={field.label}
                                                          type={field.type} values={field.values ?? []}
                                                          cols={field.cols}
                                                          precompiled={field.precompiled}
                                                          disablePast={field.disablePast}
                                                          disableFuture={field.disableFuture}
                                                          row={field.row}
                                                          profile={profile}
                                                          categoria={categoria}
                                        />
                                    }
                                }
                            )}
                        </Grid>
                    </Box>
                }
            })}
        </Box>
    )
}
