import React from "react";
import {Box} from "@mui/material";

export const Home = () => {
  return(
      <Box>
          <img style={{width:'100%'}} src={require('../assets/images/header.jpg')}/>
      </Box>
  )
}
