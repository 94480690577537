import React from "react";
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useField} from "formik";
import {Trans, useTranslation} from "react-i18next";
import CustomAgenda from "../CustomAgenda";
import {saraTheme} from "../../theme/saraTheme";

export const FormField = ({profile, label, ...props}) => {
    const [field, meta, helpers] = useField(props)
    const {t} = useTranslation()
    return (
        <Grid item xs={props.cols ? props.cols.xs : 12} sm={props.cols ? props.cols.sm : 6}>
            {(() => {
                switch (props.type) {
                    case 'divider':
                        return <Divider/>
                    case 'note':
                        return <Box>
                            <Typography>
                                <Trans i18nKey={label}/>
                            </Typography>
                        </Box>
                    case 'checkbox':
                        return <FormControl sx={{pt: 0}} fullWidth error={!!(meta.touched && meta.error)}>
                            <FormGroup>
                                <FormControlLabel
                                    disabled={profile}
                                    sx={{alignItems: 'center'}}
                                    control={<Checkbox checked={field.value}
                                                       {...field}
                                                       {...props}
                                    />} label={label}
                                />
                            </FormGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'radiogroup':
                        return <FormControl sx={{pt: 1}} variant="standard" fullWidth
                                            disabled={profile}
                                            error={!!(meta.touched && meta.error)}>
                            {label && <FormLabel id={props.id} sx={{color: saraTheme.palette.secondary.main+' !important', fontWeight: 'bold', fontSize: '1.15rem'}}>
                                {t(label, {
                                    conferma: ["DIRETTORE", "PRESIDENTE"].includes(props.categoria) ? 'Conferma' : 'Confermi',
                                    sua: ["DIRETTORE", "PRESIDENTE"].includes(props.categoria) ? 'sua' : 'tua',
                                })}
                            </FormLabel>}
                            <RadioGroup
                                aria-labelledby={props.id}
                                defaultValue={''}
                                {...field}
                                {...props}
                                row={props.row ?? true}
                            >
                                {props.values?.map(v =>
                                    <FormControlLabel sx={{
                                        alignItems: props.row === false ? 'start' : 'center',
                                        py: props.row === false ? 1 : 0
                                    }} value={v.value} key={v.value}
                                                      control={<Radio sx={{py: props.row === false ? 0 : 1}}/>}
                                                      label={v.label}/>
                                )}
                            </RadioGroup>
                            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                        </FormControl>
                    case 'textBlock':
                        return <TextField autoComplete={''} error={!!(meta.touched && meta.error)}
                                          helperText={meta.touched && meta.error}
                                          {...field}
                                          {...props}
                                          disabled={(props.precompiled || profile)}
                                          multiline
                                          minRows={3}
                                          label={<Trans i18nKey={t(label)}/>} variant={"outlined"}
                                          sx={{width: '100%'}}/>
                    case 'agenda':
                        return <CustomAgenda agendaId={props.id}/>
                    default:
                        return <TextField autoComplete={''} error={!!(meta.touched && meta.error)}
                                          helperText={meta.touched && meta.error}
                                          {...field}
                                          {...props}
                                          disabled={(props.precompiled || profile)}
                                          label={<Trans i18nKey={t(label)}/>} variant={"standard"}
                                          sx={{width: '100%'}}/>
                }
            })()}
        </Grid>
    )
}